import React, { useCallback } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import MainContainerComponent from './structure/MainContainerComponent';
import SpacingContainerComponent from './structure/SpacingContainerComponent';
import { useTranslation } from 'react-i18next';
import ButtonComponent from './form/ButtonComponent';
import LoaderComponent from './LoaderComponent';
import { APP_URL } from '../constants';

const useStyles = makeStyles(theme => ({
  desc: {
    marginBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
}));

const AppStaticComponent = ({ url, title, description, loading, hiddenBtn, children }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const handleClick = useCallback(() => {
    window.location.href = url || APP_URL;
  }, [url]);

  return (
    <MainContainerComponent>
      <Box>
        <SpacingContainerComponent className={classes.cont}>
          <Typography variant='h2' className={classes.title}>
            {title}
          </Typography>
          <Typography variant='h4' className={classes.desc}>
            {description}
          </Typography>
          {!hiddenBtn && <ButtonComponent title={t('btns.go_to_app')} onClick={handleClick} /> }
          {children}
        </SpacingContainerComponent>
      </Box>
      <LoaderComponent open={loading} />
    </MainContainerComponent>
  );
};

export default React.memo(AppStaticComponent);
