import superagent from 'superagent';
import { API_MOBILE_URL, API_URL, API_USER_COM_URL } from '../constants';

// const getLang = () => localStorage.getItem('lang') || PL_LNG;
export const POST = async (url, data = {}) => {
    // const lang = getLang();
    return superagent
        .post(`${API_URL}/pl/api${url}`)
        .set('Accept', 'application/json')
        .set('enctype', 'multipart/form-data')
        .send(data);
};

// const getLang = () => localStorage.getItem('lang') || PL_LNG;
export const DELETE = async (url, data = {}) => {
    // const lang = getLang();
    return superagent
        .delete(`${API_URL}/pl/api${url}`)
        .set('Accept', 'application/json')
        .set('enctype', 'multipart/form-data')
};
export const MOBILE_POST = async (url, data = {}) => {
    // const lang = getLang();
    return superagent
        .post(`${API_MOBILE_URL}/api${url}`)
        .set('Accept', 'application/json')
        .set('enctype', 'multipart/form-data')
        .send(data);
};
export const MOBILE_GET = async (url, data = {}) => {
    // const lang = getLang();
    return superagent
        .get(`${API_MOBILE_URL}/api${url}`)
        .set('Accept', 'application/json')
        .set('enctype', 'multipart/form-data')
};
export const USER_COM_GET = async (url, query = {}) => {
    return superagent
        .get(`${API_USER_COM_URL}${url}`)
        .set('Accept', '*/*')
        .set('Authorization', `Token ${process.env.REACT_APP_USER_COM_TOKEN}`)
        // .set('Connection', 'keep-alive')
        .query(query);
};

export const USER_COM_PUT = async (url, data = {}) => {
    return superagent
        .put(`${API_USER_COM_URL}${url}`)
        .set('Accept', '*/*')
        .set('Authorization', `Token ${process.env.REACT_APP_USER_COM_TOKEN}`)
        .set('Content-Type', 'application/json')
          .set('Connection', 'keep-alive')
        .send(data)
}
export const PUT = async (url, data = {}) => {
    return superagent
        .put(`${API_URL}/pl/api${url}`)
        .set('Accept', 'application/json')
        .set('enctype', 'multipart/form-data')
        .send(data)
}


const worker = () => {

    onmessage = (e) => {
        if (e?.request?.cache === 'only-if-cached' && e?.request?.mode !== 'same-origin') return;
        fetch(`${e.data[0]}/pl/api${e.data[1]}`, {
            headers: {
                'Accept': 'application/json',
            }
        })
            .then(function (a) {
                if (a.ok) {
                    return a.json();
                } else {
                    throw new Error(a?.status || '');
                }
            })
            .then(function (json) {
                postMessage([json, true])
            }).catch((err) => { 
                postMessage([err, false])
            })

    }
}

export const GET_PROXY = async (url, success = () => { }, error = () => { }) => {
    // const lang = getLang();
    const myWorker = new Worker(URL.createObjectURL(new Blob(["(" + worker.toString() + ")()"], { type: 'text/javascript' })));


    let data = {};
    myWorker.postMessage([API_URL, url]);
    myWorker.onmessage = function (e) {
        data = e.data[0];
        const isSucc = e.data[1];
        if (isSucc && !data?.code){
            success(data)
        }
        else{
            error(data)
        }
    }
};
export const GET = async (url, query = {}) => {
    // const lang = getLang();
    const myWorker = new Worker(URL.createObjectURL(new Blob(["(" + worker.toString() + ")()"], { type: 'text/javascript' })));


    // let data = {};
    myWorker.postMessage([API_URL, url, query,]);
    myWorker.onmessage = function (e) {
        // data = e.data;
    }
    return superagent
        .get(`${API_URL}/pl/api${url}`)
        .set('Accept', 'application/json')
        .query(query);
};
