import React from 'react';
import { Route, Switch } from 'react-router-dom';
// MAP_ROUTE
import {
  BABYROOM_ROUTE,
  CUSTOM_ROUTE,
  GASTRONOMY_ROUTE,
  MAIN_ROUTE,
  NEWS_ROUTE,
  PROMOTIONS_ROUTE,
  SERVICES_ROUTE,
  SHOPS_ROUTE,
  ENTERTAINMENT_ROUTE,
  // CLOAKROOM_ROUTE,
  LOCALIZATION_ROUTE,
   STYLIST_TIPS_ROUTE,
  PARKINGS_ROUTE,
  ABOUT_ROUTE,
  COMFORT_ROUTE,
  NUMBER_ONE_ROUTE,
  INFORMATION_ROUTE,
  // FASHION_INSPIRATION_ROUTE,
  CONTACT_ROUTE,
  PLAN_ROUTE,
  SALES_TABLE_ROUTE,
  CAREER_ROUTE,
  GALLERY_ROUTE,
  SAFE_SHOPING_ROUTE,
  GIFT_ROUTE,
  SPECIAL_PROMOTIONS_ROUTE,
  EVENTS_ROUTE,
  QUESTIONNAIRE_ROUTE,
  VIDEO_GALLERY_ROUTE,
  HELP_UKRAINE_ROUTE,
  CONFIRM_REGISTER_ROUTE,
  RESET_PASSWORD_ROUTE,
  CHECK_REWARDS_ROUTE,
  REGULATION_ROUTE,
  APPLICATION_ROUTE,
  SHARE_PROMO_CODE_ROUTE,
  SUCCESS_PAY_ROUTE,
  FAILURE_PAY_ROUTE,
  UPDATED_POLICIES_ROUTE,
  SOCIAL_AUTH_CALLBACK_ROUTE,
  CONFIRM_DELETE_STYLIST_ROUTE
} from './constants/routes';
import MainPage from './pages/mainPage/MainPage';
// import AboutPage from "./pages/aboutPage/AboutPage";
// import ArticlePage from "./pages/articlePage/ArticlePage";
// import CareerOfferPage from "./pages/careerOfferPage/CareerOfferPage";
// import CareerPage from "./pages/careerPage/CareerPage";
// import CloakroomPage from "./pages/cloakroomPage/CloakroomPage";
// import ComfortPage from "./pages/comfortPage/ComfortPage";
// import ContactPage from "./pages/contactPage/ContactPage";
// import CustomPage from "./pages/customPage/CustomPage";
// import EmptyPage from "./pages/emptyPage/EmptyPage";
// import EntertainmentPage from "./pages/entertainmentPage/EntertainmentPage";
// import FashionInspirationPage from "./pages/fashionInspirationPage/FashionInspirationPage";
// import GalleryPage from "./pages/galleryPage/GalleryPage";
// import GastronomyPage from "./pages/gastronomyPage/GastronomyPage";
// import GastronomyShopPage from "./pages/gastronomyShopPage/GastronomyShopPage";
// import GiftCardPage from "./pages/giftCardPage/GiftCardPage";
// import HappyPortPage from "./pages/happyPortPage/HappyPortPage";
// import InformationPage from "./pages/informationPage/InformationPage";
// import LocalizationPage from "./pages/localizationPage/LocalizationPage";
// import NewsPage from "./pages/newsPage/NewsPage";
// import NumberOnePage from "./pages/numberOnePage/NumberOnePage";
// import ParkingsPage from "./pages/parkingsPage/ParkingsPage";
// import PlanPage from "./pages/planPage/PlanPage";
// import PromotionPage from "./pages/promotionPage/PromotionPage";
// import PromotionsPage from "./pages/promotionsPage/PromotionsPage";
// import SafeShoppingPage from "./pages/safeShoppingPage/SafeShoppingPage";
// import SalesTablePage from "./pages/salesTablePage/SalesTablePage";
// import ServicePage from "./pages/servicePage/ServicePage";
// import ServicesPage from "./pages/servicesPage/ServicesPage";
// import ShopPage from "./pages/shopPage/ShopPage";
// import ShopsPage from "./pages/shopsPage/ShopsPage";
// import SpecialPromotionsPage from "./pages/specialPromotionsPage/SpecialPromotionsPage";
// import StylistTipsPage from "./pages/stylistTipsPage/StylistTipsPage";
// import EventsPage from "./pages/eventsPage/EventsPage";
// import EventPage from "./pages/eventPage/EventPage";
// import BabyroomPage from "./pages/babyroomPage/BabyroomPage";
// import QuestionnairePage from "./pages/questionnairePage/QuestionnairePage";
// import GalleriesPage from "./pages/galleriesPage/GalleriesPage";
// import VideosPage from "./pages/videosPage/VideosPage";
import loadable from '@loadable/component';
import LoaderComponent from './components/LoaderComponent';
import SocialAuthCallbackPage from './pages/socialAuthCallbackPage/SocialAuthCallbackPage';
import ConfirmDeletePage from './pages/confirmDeletePage/ConfirmDeletePage';
// import ConfirmRegisterPage from "./pages/confirmRegisterPage/ConfirmRegisterPage";
// import ResetPasswordPage from "./pages/resetPasswordPage/ResetPasswordPage";
// import CheckRewardsPage from "./pages/checkRewardsPage/CheckRewardsPage";
// import ApplicationPage from "./pages/applicationPage/ApplicationPage";
// import HelpUkrainePostPage from "./pages/helpUkrainePostPage/HelpUkrainePostPage";

const SuccessPayPage = loadable(
  () => import('./pages/successPayPage/SuccessPayPage'),
  {
    fallback: <LoaderComponent open />,
  },
);

const FailurePayPage = loadable(
  () => import('./pages/failurePayPage/FailurePayPage'),
  {
    fallback: <LoaderComponent open />,
  },
);

const ConfirmRegisterPage = loadable(
  () => import('./pages/confirmRegisterPage/ConfirmRegisterPage'),
  {
    fallback: <LoaderComponent open />,
  },
);
const ResetPasswordPage = loadable(
  () => import('./pages/resetPasswordPage/ResetPasswordPage'),
  {
    fallback: <LoaderComponent open />,
  },
);
const CheckRewardsPage = loadable(
  () => import('./pages/checkRewardsPage/CheckRewardsPage'),
  {
    fallback: <LoaderComponent open />,
  },
);
const ApplicationPage = loadable(
  () => import('./pages/applicationPage/ApplicationPage'),
  {
    fallback: <LoaderComponent open />,
  },
);
const AboutPage = loadable(() => import('./pages/aboutPage/AboutPage'), {
  fallback: <LoaderComponent open />,
});
const ArticlePage = loadable(() => import('./pages/articlePage/ArticlePage'), {
  fallback: <LoaderComponent open />,
});
const CareerOfferPage = loadable(
  () => import('./pages/careerOfferPage/CareerOfferPage'),
  {
    fallback: <LoaderComponent open />,
  },
);
const CareerPage = loadable(() => import('./pages/careerPage/CareerPage'), {
  fallback: <LoaderComponent open />,
});
// const CloakroomPage = loadable(() => import('./pages/cloakroomPage/CloakroomPage'), {
//   fallback: <LoaderComponent open />,
// });
const ComfortPage = loadable(() => import('./pages/comfortPage/ComfortPage'), {
  fallback: <LoaderComponent open />,
});
const ContactPage = loadable(() => import('./pages/contactPage/ContactPage'), {
  fallback: <LoaderComponent open />,
});

const RegulationPage = loadable(
  () => import('./pages/regulationPage/RegulationPage'),
  {
    fallback: <LoaderComponent open />,
  },
);
const CustomPage = loadable(() => import('./pages/customPage/CustomPage'), {
  fallback: <LoaderComponent open />,
});
const EmptyPage = loadable(() => import('./pages/emptyPage/EmptyPage'), {
  fallback: <LoaderComponent open />,
});
const EntertainmentPage = loadable(
  () => import('./pages/entertainmentPage/EntertainmentPage'),
  {
    fallback: <LoaderComponent open />,
  },
);
// const FashionInspirationPage = loadable(() => import('./pages/fashionInspirationPage/FashionInspirationPage'), {
//   fallback: <LoaderComponent open />,
// });
const GalleryPage = loadable(() => import('./pages/galleryPage/GalleryPage'), {
  fallback: <LoaderComponent open />,
});
const GastronomyPage = loadable(
  () => import('./pages/gastronomyPage/GastronomyPage'),
  {
    fallback: <LoaderComponent open />,
  },
);
const GastronomyShopPage = loadable(
  () => import('./pages/gastronomyShopPage/GastronomyShopPage'),
  {
    fallback: <LoaderComponent open />,
  },
);

const GiftCardPage = loadable(
  () => import('./pages/giftCardPage/GiftCardPage'),
  {
    fallback: <LoaderComponent open />,
  },
);

const HappyPortPage = loadable(
  () => import('./pages/happyPortPage/HappyPortPage'),
  {
    fallback: <LoaderComponent open />,
  },
);

const InformationPage = loadable(
  () => import('./pages/informationPage/InformationPage'),
  {
    fallback: <LoaderComponent open />,
  },
);

const LocalizationPage = loadable(
  () => import('./pages/localizationPage/LocalizationPage'),
  {
    fallback: <LoaderComponent open />,
  },
);

const NewsPage = loadable(() => import('./pages/newsPage/NewsPage'), {
  fallback: <LoaderComponent open />,
});

const NumberOnePage = loadable(
  () => import('./pages/numberOnePage/NumberOnePage'),
  {
    fallback: <LoaderComponent open />,
  },
);

const ParkingsPage = loadable(
  () => import('./pages/parkingsPage/ParkingsPage'),
  {
    fallback: <LoaderComponent open />,
  },
);

const PlanPage = loadable(() => import('./pages/planPage/PlanPage'), {
  fallback: <LoaderComponent open />,
});

const PromotionPage = loadable(
  () => import('./pages/promotionPage/PromotionPage'),
  {
    fallback: <LoaderComponent open />,
  },
);

const PromotionsPage = loadable(
  () => import('./pages/promotionsPage/PromotionsPage'),
  {
    fallback: <LoaderComponent open />,
  },
);

const SafeShoppingPage = loadable(
  () => import('./pages/safeShoppingPage/SafeShoppingPage'),
  {
    fallback: <LoaderComponent open />,
  },
);
const SalesTablePage = loadable(
  () => import('./pages/salesTablePage/SalesTablePage'),
  {
    fallback: <LoaderComponent open />,
  },
);
const ServicePage = loadable(() => import('./pages/servicePage/ServicePage'), {
  fallback: <LoaderComponent open />,
});
const ServicesPage = loadable(
  () => import('./pages/servicesPage/ServicesPage'),
  {
    fallback: <LoaderComponent open />,
  },
);
const ShopPage = loadable(() => import('./pages/shopPage/ShopPage'), {
  fallback: <LoaderComponent open />,
});
const ShopsPage = loadable(() => import('./pages/shopsPage/ShopsPage'), {
  fallback: <LoaderComponent open />,
});
const SpecialPromotionsPage = loadable(
  () => import('./pages/specialPromotionsPage/SpecialPromotionsPage'),
  {
    fallback: <LoaderComponent open />,
  },
);
const StylistTipsPage = loadable(() => import('./pages/stylistTipsPage/StylistTipsPage'), {
  fallback: <LoaderComponent open />,
});
const EventsPage = loadable(() => import('./pages/eventsPage/EventsPage'), {
  fallback: <LoaderComponent open />,
});
const EventPage = loadable(() => import('./pages/eventPage/EventPage'), {
  fallback: <LoaderComponent open />,
});
const BabyroomPage = loadable(
  () => import('./pages/babyroomPage/BabyroomPage'),
  {
    fallback: <LoaderComponent open />,
  },
);
const QuestionnairePage = loadable(
  () => import('./pages/questionnairePage/QuestionnairePage'),
  {
    fallback: <LoaderComponent open />,
  },
);
const GalleriesPage = loadable(
  () => import('./pages/galleriesPage/GalleriesPage'),
  {
    fallback: <LoaderComponent open />,
  },
);
const VideosPage = loadable(() => import('./pages/videosPage/VideosPage'), {
  fallback: <LoaderComponent open />,
});
const HelpUkrainePage = loadable(
  () => import('./pages/helpUkrainePage/HelpUkrainePage'),
  {
    fallback: <LoaderComponent open />,
  },
);
const SharePromoCodePage = loadable(
  () => import('./pages/sharePromoCodePage/SharePromoCodePage'),
  {
    fallback: <LoaderComponent open />,
  },
);
const UpdatedPoliciesPage = loadable(() => import('./pages/updatedPoliciesPage/UpdatedPoliciesPage'), {
  fallback: <LoaderComponent open />,
});

const Root = props => {
  return (
    // <BrowserRouter>
    <Switch>
      <Route exact path={ENTERTAINMENT_ROUTE} component={HappyPortPage} />
      <Route exact path={GASTRONOMY_ROUTE} component={GastronomyPage} />
      <Route
        exact
        path={`${GASTRONOMY_ROUTE}/:slug`}
        component={GastronomyShopPage}
      />
      <Route exact path={EVENTS_ROUTE} component={EventsPage} />
      <Route exact path={`${EVENTS_ROUTE}/:slug`} component={EventPage} />
      <Route exact path={SHOPS_ROUTE} component={ShopsPage} />
      <Route exact path={PROMOTIONS_ROUTE} component={PromotionsPage} />
      <Route
        exact
        path={`${PROMOTIONS_ROUTE}/:slug`}
        component={PromotionPage}
      />
      <Route exact path={`${NEWS_ROUTE}/:slug`} component={ArticlePage} />
      <Route exact path={NEWS_ROUTE} component={NewsPage} />
      <Route exact path={SERVICES_ROUTE} component={ServicesPage} />
      <Route
        exact
        path={`${ENTERTAINMENT_ROUTE}/:slug`}
        component={EntertainmentPage}
      />
      <Route exact path={`${SHOPS_ROUTE}/:slug`} component={ShopPage} />
      <Route exact path={`${SERVICES_ROUTE}/:slug`} component={ServicePage} />
      {/* <Route exact path={CLOAKROOM_ROUTE} component={CloakroomPage} /> */}
      <Route exact path={LOCALIZATION_ROUTE} component={LocalizationPage} />
      <Route exact path={STYLIST_TIPS_ROUTE} component={StylistTipsPage} />
      <Route exact path={PARKINGS_ROUTE} component={ParkingsPage} />
      <Route exact path={ABOUT_ROUTE} component={AboutPage} />
      <Route exact path={COMFORT_ROUTE} component={ComfortPage} />
      <Route exact path={NUMBER_ONE_ROUTE} component={NumberOnePage} />
      <Route exact path={INFORMATION_ROUTE} component={InformationPage} />
      {/* <Route exact path={FASHION_INSPIRATION_ROUTE} component={FashionInspirationPage} /> */}
      <Route exact path={CONTACT_ROUTE} component={ContactPage} />
      <Route exact path={PLAN_ROUTE} component={PlanPage} />
      <Route exact path={SALES_TABLE_ROUTE} component={SalesTablePage} />
      <Route exact path={`${CUSTOM_ROUTE}/:slug`} component={CustomPage} />
      <Route
        exact
        path={`${REGULATION_ROUTE}/:slug`}
        component={RegulationPage}
      />
      <Route exact path={`${CAREER_ROUTE}/:slug`} component={CareerOfferPage} />
      <Route exact path={GALLERY_ROUTE} component={GalleriesPage} />
      <Route exact path={VIDEO_GALLERY_ROUTE} component={VideosPage} />
      <Route exact path={`${GALLERY_ROUTE}/:slug`} component={GalleryPage} />
      <Route exact path={CAREER_ROUTE} component={CareerPage} />
      <Route exact path={`${UPDATED_POLICIES_ROUTE}/:email`} component={UpdatedPoliciesPage} />
      <Route exact path={SAFE_SHOPING_ROUTE} component={SafeShoppingPage} />
      <Route exact path={GIFT_ROUTE} component={GiftCardPage} />
      <Route exact path={BABYROOM_ROUTE} component={BabyroomPage} />
      <Route
        exact
        path={`${SPECIAL_PROMOTIONS_ROUTE}/:slug`}
        component={SpecialPromotionsPage}
      />
      <Route
        exact
        path={`${CONFIRM_REGISTER_ROUTE}/:id/:hash`}
        component={ConfirmRegisterPage}
      />
      <Route
        exact
        path={`${CONFIRM_DELETE_STYLIST_ROUTE}/:id`}
        component={ConfirmDeletePage}
      />
      <Route
        exact
        path={`${RESET_PASSWORD_ROUTE}/:slug`}
        component={ResetPasswordPage}
      />
      <Route exact path={QUESTIONNAIRE_ROUTE} component={QuestionnairePage} />
      <Route exact path={HELP_UKRAINE_ROUTE} component={HelpUkrainePage} />
      <Route
        exact
        path={`${CHECK_REWARDS_ROUTE}/:slug`}
        component={CheckRewardsPage}
      />
      <Route
        exact
        path={`${SHARE_PROMO_CODE_ROUTE}/:code`}
        component={SharePromoCodePage}
      />
      {/* <Route exact path={`${HELP_UKRAINE_ROUTE}/:slug`} component={HelpUkrainePostPage} /> */}
      <Route exact path={APPLICATION_ROUTE} component={ApplicationPage} />
      <Route exact path={`${SOCIAL_AUTH_CALLBACK_ROUTE}/:provider`} component={SocialAuthCallbackPage} />
      <Route exact path={SUCCESS_PAY_ROUTE} component={SuccessPayPage} />
      <Route exact path={FAILURE_PAY_ROUTE} component={FailurePayPage} />
      <Route exact path={MAIN_ROUTE} component={MainPage} />
      <Route path='*' component={EmptyPage} />
    </Switch>
    // </BrowserRouter>
  );
};

export default Root;
