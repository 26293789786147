import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { EMPTY_ROUTE } from '../../constants/routes';
import { DELETE } from '../../functions/superagentSending';
import { STYLIST_TIPS_API } from '../../constants/api';
import AppStaticComponent from '../../components/AppStaticComponent';
import ButtonComponent from '../../components/form/ButtonComponent';

const ConfirmDeletePage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { id } = useParams();
  const [title, description] = useMemo(() => {

    if(success){
      return [t('confirm_delete_page.success_title'), t('confirm_delete_page.success_desc')];
    }

    if(error){
      return [t('confirm_delete_page.error_title'), t('confirm_delete_page.error_desc')];
    }

    return [t('confirm_delete_page.confirm_title'), t('confirm_delete_page.confirm_desc')];
  }, [error, success, t])


  const handleRemove = useCallback(() => {
    setError(false);
    setOpen(true);
    if (id) {
      DELETE(`${STYLIST_TIPS_API}/${id}`)
        .then(() => {
          setOpen(false);
          setSuccess(true);
        })
        .catch(() => {
          setOpen(false);
          setError(true);
        });
    } else {
      setOpen(false);
      history.push(EMPTY_ROUTE);
    }
  }, [history, id]);

  return (
    <AppStaticComponent
      title={title}
      description={description}
      loading={open}
      hiddenBtn
    >
      {!success && <ButtonComponent title={t('btns.delete_stylist')} onClick={handleRemove} /> }
    </AppStaticComponent>
  );
};

export default React.memo(ConfirmDeletePage);
