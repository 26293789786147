export const MAIN_ROUTE = '/';
export const CAREER_ROUTE = '/praca';
export const NEWS_ROUTE = '/aktualnosci';
export const CONTACT_ROUTE = '/kontakt';
export const RENTAL_ROUTE = '/strefa-najemcy';
export const PLAN_ROUTE = '/plan-centrum';
export const GIFT_ROUTE = '/karta-podarunkowa';
export const FASHION_INSPIRATION_ROUTE = '/modowe-inspiracje';
export const PROMOTIONS_ROUTE = '/promocje';
export const SPECIAL_PROMOTIONS_ROUTE = '/promocje-specjalne';
export const SALES_TABLE_ROUTE = '/tabela-rabatow';
export const GASTRONOMY_ROUTE = '/gastronomia';
export const SHOPS_ROUTE = '/sklepy';
export const SERVICES_ROUTE = '/uslugi';
export const ENTERTAINMENT_ROUTE = '/port-rozrywki';
export const QUESTIONNAIRE_ROUTE = '/ankieta';
export const BOTTLEMAT_ROUTE = '/uslugi/butelkomat-2';
export const CLOAKROOM_ROUTE = '/szatnia';
export const EVENTS_ROUTE = '/niedzielne-atrakcje';
export const BABYROOM_ROUTE = '/babyroom';
export const LOCALIZATION_ROUTE = '/jak-trafic';
export const STYLIST_TIPS_ROUTE = '/bezplatne-porady-stylistki';
export const PARKINGS_ROUTE = '/parking';
export const ABOUT_ROUTE = '/o-galaxy';
export const COMFORT_ROUTE = '/udogodnienia';
export const NUMBER_ONE_ROUTE = '/numer-1-w-województwie';
export const INFORMATION_ROUTE = '/informacje';
export const GALLERY_ROUTE = '/galeria-zdjec';
export const VIDEO_GALLERY_ROUTE = '/galeria-wideo';
export const CUSTOM_ROUTE = '/strona';
export const SAFE_SHOPING_ROUTE = '/bezpieczny-zakupy';
export const EMPTY_ROUTE = '/404';
export const HELP_UKRAINE_ROUTE = '/pomoc-ukrainie';
export const CHECK_REWARDS_ROUTE = '/check-reward';
export const CONFIRM_REGISTER_ROUTE = '/confirm-register';
export const CONFIRM_DELETE_STYLIST_ROUTE = '/confirm-cancel';
export const RESET_PASSWORD_ROUTE = '/reset-password';
export const REGULATION_ROUTE = '/regulamin';
export const APPLICATION_ROUTE = '/aplikacja';
export const SHARE_PROMO_CODE_ROUTE = '/pobierz-aplikacje';
export const SUCCESS_PAY_ROUTE = '/successPay';
export const FAILURE_PAY_ROUTE = '/failurePay';
export const SOCIAL_AUTH_CALLBACK_ROUTE = '/callback';

export const HAPPY_PORT_FITNESS_SLUG = 'fitness';
export const KIDS_PLAY_SLUG = 'kids-play';
export const CAR_SHARING_SLUG = 'panek';
export const NOXO_SLUG = 'noxo';
export const VOLUNTEER_FRIDGE_SLUG = 'lodowka-spoleczna';
export const BOOKCROSSING_SLUG = 'strefa-bookcrossingu';
export const HAPPY_PORT_CINEMA_SLUG = 'multikino';
export const HAPPY_PORT_BOWLING_SLUG = 'mk-bowling';
export const PROCESSING_POLICY_SLUG = '/polityka-przetwarzania-danych-osobowych-polityka-transparentnosci';
export const APP_RULES_SLUG = '/regulamin-app';
export const APP_POLICY_SLUG = '/polityka-prywatnosci-app';

export const STORE_REGULATIONS = 'https://www.galaxy-centrum.pl/files/regulaminy/REGULAMIN_CENTRUM_HANDLOWEGO.pdf';
export const PERSONAL_DATA_POLICY = 'https://www.galaxy-centrum.pl/files/regulaminy/Polityka_ochrony%20danych_osobowych.pdf';

export const UPDATED_POLICIES_ROUTE = '/zmiana-ado';
export const POLICY_SLUG = '/polityka-prywatnosci';
export const TRANSPARENCY_POLICY_SLUG = '/polityka-transparentnosci';
export const NEWSLETTER_REGULATIONS_SLUG = '/regulamin-uslugi-newsletter'
